:root {
    // COLORS
    --black: #000000;
    --white: #ffffff;

    --primary-color: #eab464;
    --primary-color-dark: #c18733;

    --secondary-color: #222222;
    --titles-color: #baa997;
    --error-color: #ef4f57;
    --success-color: #5e8677;

    --brown-light: #bcb8ae;
    --brown-domino: #9c8267;
    --brown-akaroa: #DCCCBB;

    --gray-dark: #565656;
    --gray-light: #fafafa;
    --gray-medium: #dbdad7;
    --gray-mercury: #e5e5e5;
    --gray-wood: #f8f5f1;
    --gray-seashell: #f1f1f1;
    --gray-boulder: #7a7a7a;
    --gray-alto: #d3d3d3;
    --gray-tundora :#4B4B4B;

    --green-gin: #e9f0ed;
    --green-summer: #92b4a7;

    --orange-linen: #faefdf;
    --orange-pampas: #ebe7e3;

    // SIZES
    --header-height: 55px;

    @media screen and (min-width: 900px) {
        --header-height: 64px;
    }
}
