.PetSelect {
    $root: &;

    display: flex;
    padding: 25px 0px;
    position: relative;
    // align-items: center;

    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
    }

    &-wrapper {
        &[data-edit='false'] {
            border-bottom: solid 1px var(--brown-light);
        }
    }

    .MuiTouchRipple-root {
        display: none;
    }

    .MuiRadio {
        &-root {
            padding: 0px;
            margin-top: 22px;
            align-items: flex-start;
            color: var(--secondary-color);
            // background-color: var(--white);

            &:hover {
                background-color: transparent;
            }

            &.Mui-checked {
                svg {
                    font-size: 20px;
                    margin: 2px 2px 0px;
                    color: var(--secondary-color);

                    @media screen and (min-width: 769px) {
                        font-size: 23px;
                    }
                }
            }
        }
    }

    &-service-mobile {
        flex: 0 0 100%;

        @media screen and (min-width: 768px) {
            display: none;
        }
    }

    &-image {
        width: 68px;
        height: 68px;
        flex: 0 0 68px;
        font-size: 28px;
        overflow: hidden;
        margin-left: 15px;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-dogs {
        .PetSelect-image {
            color: var(--success-color);
            background-color: var(--green-gin);
        }
    }

    &-cats {
        .PetSelect-image {
            color: var(--primary-color-dark);
            background-color: var(--orange-linen);
        }
    }

    &-exotics {
        .PetSelect-image {
            color: var(--brown-domino);
            background-color: var(--orange-pampas);
        }
    }

    &-info {
        flex: 1;
        overflow: hidden;
        margin-left: 10px;

        @media screen and (min-width: 900px) {
            margin-left: 16px;
        }

        @media screen and (max-width: 767px) {
            .CustomSelect {
                display: none;
            }
        }

        &-name,
        &-description {
            margin-top: 0px;
        }

        &-name {
            gap: 8px;
            display: flex;
            flex-wrap: wrap;
            font-size: 24px;
            font-weight: 500;
            line-height: 100%;
            margin-bottom: 10px;
            padding-right: 60px;
            color: var(--secondary-color);

            @media screen and (min-width: 900px) {
                font-size: 26px;
            }
        }

        &-description {
            font-size: 15px;
            line-height: 130%;
            margin-bottom: 0px;
            color: var(--black);
            padding-right: 20px;

            @media screen and (min-width: 900px) {
                font-size: 17px;
                padding-right: 0px;
            }
        }
    }

    .CustomSelect {
        .MuiFormLabel-filled {
            display: none;

            & + div {
                &::after {
                    content: '';
                    right: 14px;
                    width: 26px;
                    height: 26px;
                    display: block;
                    position: absolute;
                    background-size: contain;
                    background-image: url('../../../assets/images/icons/icon-check.svg');
                }
            }
        }

        .MuiSelect-select {
            background-size: 18px;
            background-repeat: no-repeat;
            background-position: 96% center;
            background-image: url('../../../assets/images/icons/icon-angle-down.svg');
        }

        svg {
            display: none;
        }
    }

    &-badge {
        font-size: 14px;
        line-height: 25px;
        padding: 1px 10px;
        border-radius: 23px;
        display: inline-block;
        vertical-align: middle;

        &-dogs {
            color: var(--white);
            background-color: var(--success-color);
        }

        &-cats {
            color: var(--secondary-color);
            background-color: var(--orange-linen);
        }

        &-exotics {
            color: var(--brown-domino);
            background-color: var(--orange-pampas);
        }
    }

    &-edit {
        top: 25px;
        right: 0px;
        margin-left: auto;
        padding-top: 10px;
        position: absolute;
        align-self: flex-start;

        @media screen and (max-width: 900px) {
            top: 19px;
            right: 0px;
            position: absolute;
        }

        p {
            margin: 0px;
            cursor: pointer;
            font-size: 15px;
            line-height: 110%;
            color: var(--gray-boulder);

            @media screen and (min-width: 900px) {
                font-size: 16px;
            }

            &:hover span {
                text-decoration: none;
            }

            span {
                text-decoration: underline;
            }
        }

        svg {
            margin-right: 7px;
        }
    }
}
