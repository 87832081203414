html,
body {
    margin: 0;
    height: 100%;
    overflow: hidden;
    color: #222222;
    font-size: 16px;
    line-height: 1.3;
    background-color: var(--gray-light);
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;

    @media (min-width: 900px) {
        font-size: 18px;
    }
}

#root {
    @media (max-width: 900px) {
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        position: fixed;
        z-index: 1000;
    }
}

.app-container {
    height: 100vh;
    height: 100dvh;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex !important;
    flex-direction: column;
}

.MuiMenu {
    &-paper {
        max-height: 300px !important;
    }

    &Item-root {
        &.Mui-selected {
            color: var(--white);
            background-color: var(--secondary-color) !important;
        }
    }
}
